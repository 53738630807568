export const ControllerBundleCustomization = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundleCustomization.js' /* webpackChunkName: "components/controller-bundle-customization" */).then(c => wrapFunctional(c.default || c))
export const ControllerBundles = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundles.js' /* webpackChunkName: "components/controller-bundles" */).then(c => wrapFunctional(c.default || c))
export const ControllerCalendar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js' /* webpackChunkName: "components/controller-calendar" */).then(c => wrapFunctional(c.default || c))
export const ControllerCart = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js' /* webpackChunkName: "components/controller-cart" */).then(c => wrapFunctional(c.default || c))
export const ControllerCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js' /* webpackChunkName: "components/controller-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerCommentList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCommentList.js' /* webpackChunkName: "components/controller-comment-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerConfirmEmail = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerConfirmEmail.js' /* webpackChunkName: "components/controller-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const ControllerContactForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js' /* webpackChunkName: "components/controller-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerCookie = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCookie.js' /* webpackChunkName: "components/controller-cookie" */).then(c => wrapFunctional(c.default || c))
export const ControllerCrossSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCrossSell.js' /* webpackChunkName: "components/controller-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerCustomForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js' /* webpackChunkName: "components/controller-custom-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerError = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js' /* webpackChunkName: "components/controller-error" */).then(c => wrapFunctional(c.default || c))
export const ControllerEvent = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js' /* webpackChunkName: "components/controller-event" */).then(c => wrapFunctional(c.default || c))
export const ControllerFeaturedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js' /* webpackChunkName: "components/controller-featured-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerFooter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js' /* webpackChunkName: "components/controller-footer" */).then(c => wrapFunctional(c.default || c))
export const ControllerHeader = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js' /* webpackChunkName: "components/controller-header" */).then(c => wrapFunctional(c.default || c))
export const ControllerLayeredNavigation = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js' /* webpackChunkName: "components/controller-layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationBar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js' /* webpackChunkName: "components/controller-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationItem = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js' /* webpackChunkName: "components/controller-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const ControllerNewsletter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js' /* webpackChunkName: "components/controller-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ControllerPost = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js' /* webpackChunkName: "components/controller-post" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js' /* webpackChunkName: "components/controller-post-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js' /* webpackChunkName: "components/controller-post-category-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js' /* webpackChunkName: "components/controller-post-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostReview = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostReview.js' /* webpackChunkName: "components/controller-post-review" */).then(c => wrapFunctional(c.default || c))
export const ControllerProduct = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js' /* webpackChunkName: "components/controller-product" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCategories = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCategories.js' /* webpackChunkName: "components/controller-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductConfiguration = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js' /* webpackChunkName: "components/controller-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCustomOptions = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCustomOptions.js' /* webpackChunkName: "components/controller-product-custom-options" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js' /* webpackChunkName: "components/controller-product-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductListContainer = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductListContainer.js' /* webpackChunkName: "components/controller-product-list-container" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductsListFiltered = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductsListFiltered.js' /* webpackChunkName: "components/controller-products-list-filtered" */).then(c => wrapFunctional(c.default || c))
export const ControllerPublicUpload = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPublicUpload.js' /* webpackChunkName: "components/controller-public-upload" */).then(c => wrapFunctional(c.default || c))
export const ControllerRecentlyViewedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRecentlyViewedProducts.js' /* webpackChunkName: "components/controller-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerRelatedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js' /* webpackChunkName: "components/controller-related-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerSearch = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js' /* webpackChunkName: "components/controller-search" */).then(c => wrapFunctional(c.default || c))
export const ControllerUpSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUpSell.js' /* webpackChunkName: "components/controller-up-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerUserComment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUserComment.js' /* webpackChunkName: "components/controller-user-comment" */).then(c => wrapFunctional(c.default || c))
export const ControllerAddress = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js' /* webpackChunkName: "components/controller-address" */).then(c => wrapFunctional(c.default || c))
export const ControllerPayment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js' /* webpackChunkName: "components/controller-payment" */).then(c => wrapFunctional(c.default || c))
export const ControllerShipping = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js' /* webpackChunkName: "components/controller-shipping" */).then(c => wrapFunctional(c.default || c))
export const ControllerChangePassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js' /* webpackChunkName: "components/controller-change-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerForgotPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js' /* webpackChunkName: "components/controller-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js' /* webpackChunkName: "components/controller-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerOrderHistory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js' /* webpackChunkName: "components/controller-order-history" */).then(c => wrapFunctional(c.default || c))
export const ControllerRegister = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js' /* webpackChunkName: "components/controller-register" */).then(c => wrapFunctional(c.default || c))
export const ControllerResetPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js' /* webpackChunkName: "components/controller-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLogin.js' /* webpackChunkName: "components/controller-social-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLoginButtons = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js' /* webpackChunkName: "components/controller-social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const ControllerUser = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js' /* webpackChunkName: "components/controller-user" */).then(c => wrapFunctional(c.default || c))
export const ControllerWishlist = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js' /* webpackChunkName: "components/controller-wishlist" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorIcon.js' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorImage = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js' /* webpackChunkName: "components/editor-image" */).then(c => wrapFunctional(c.default || c))
export const EditorImageMarkers = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImageMarkers.js' /* webpackChunkName: "components/editor-image-markers" */).then(c => wrapFunctional(c.default || c))
export const EditorInstagramPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorInstagramPostList.js' /* webpackChunkName: "components/editor-instagram-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorLink = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js' /* webpackChunkName: "components/editor-link" */).then(c => wrapFunctional(c.default || c))
export const EditorList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorList.js' /* webpackChunkName: "components/editor-list" */).then(c => wrapFunctional(c.default || c))
export const EditorListItem = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js' /* webpackChunkName: "components/editor-list-item" */).then(c => wrapFunctional(c.default || c))
export const EditorMap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js' /* webpackChunkName: "components/editor-map" */).then(c => wrapFunctional(c.default || c))
export const EditorPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorPostList.js' /* webpackChunkName: "components/editor-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductCategoryList.js' /* webpackChunkName: "components/editor-product-category-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductList.js' /* webpackChunkName: "components/editor-product-list" */).then(c => wrapFunctional(c.default || c))
export const EditorTipTap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js' /* webpackChunkName: "components/editor-tip-tap" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCartView = () => import('../../components/cmsOverrides/CartView.vue' /* webpackChunkName: "components/cms-overrides-cart-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCheckoutSuccessView = () => import('../../components/cmsOverrides/CheckoutSuccessView.vue' /* webpackChunkName: "components/cms-overrides-checkout-success-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCheckoutView = () => import('../../components/cmsOverrides/CheckoutView.vue' /* webpackChunkName: "components/cms-overrides-checkout-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCookieElement = () => import('../../components/cmsOverrides/CookieElement.vue' /* webpackChunkName: "components/cms-overrides-cookie-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesErrorView = () => import('../../components/cmsOverrides/ErrorView.vue' /* webpackChunkName: "components/cms-overrides-error-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesFooterElement = () => import('../../components/cmsOverrides/FooterElement.vue' /* webpackChunkName: "components/cms-overrides-footer-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesForgotPasswordView = () => import('../../components/cmsOverrides/ForgotPasswordView.vue' /* webpackChunkName: "components/cms-overrides-forgot-password-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesHeaderElement = () => import('../../components/cmsOverrides/HeaderElement.vue' /* webpackChunkName: "components/cms-overrides-header-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesLoginView = () => import('../../components/cmsOverrides/LoginView.vue' /* webpackChunkName: "components/cms-overrides-login-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostCategory = () => import('../../components/cmsOverrides/PostCategory.vue' /* webpackChunkName: "components/cms-overrides-post-category" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostList = () => import('../../components/cmsOverrides/PostList.vue' /* webpackChunkName: "components/cms-overrides-post-list" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesPostView = () => import('../../components/cmsOverrides/PostView.vue' /* webpackChunkName: "components/cms-overrides-post-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesProfileView = () => import('../../components/cmsOverrides/ProfileView.vue' /* webpackChunkName: "components/cms-overrides-profile-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesRegisterView = () => import('../../components/cmsOverrides/RegisterView.vue' /* webpackChunkName: "components/cms-overrides-register-view" */).then(c => wrapFunctional(c.default || c))
export const UiButtonsMultipleBorderedButton = () => import('../../components/ui/buttons/MultipleBorderedButton.vue' /* webpackChunkName: "components/ui-buttons-multiple-bordered-button" */).then(c => wrapFunctional(c.default || c))
export const UiFormsCustomForm = () => import('../../components/ui/forms/CustomForm.vue' /* webpackChunkName: "components/ui-forms-custom-form" */).then(c => wrapFunctional(c.default || c))
export const UiFormsOrderReview = () => import('../../components/ui/forms/OrderReview.vue' /* webpackChunkName: "components/ui-forms-order-review" */).then(c => wrapFunctional(c.default || c))
export const UiGalleryDeckGalleryComponent = () => import('../../components/ui/gallery/DeckGalleryComponent.vue' /* webpackChunkName: "components/ui-gallery-deck-gallery-component" */).then(c => wrapFunctional(c.default || c))
export const UiGalleryDescriptionGalleryComponent = () => import('../../components/ui/gallery/DescriptionGalleryComponent.vue' /* webpackChunkName: "components/ui-gallery-description-gallery-component" */).then(c => wrapFunctional(c.default || c))
export const UiInputsCustomNumberInput = () => import('../../components/ui/inputs/CustomNumberInput.vue' /* webpackChunkName: "components/ui-inputs-custom-number-input" */).then(c => wrapFunctional(c.default || c))
export const UiInputsRadio = () => import('../../components/ui/inputs/Radio.vue' /* webpackChunkName: "components/ui-inputs-radio" */).then(c => wrapFunctional(c.default || c))
export const UiInputsRatingStarsComponent = () => import('../../components/ui/inputs/RatingStarsComponent.vue' /* webpackChunkName: "components/ui-inputs-rating-stars-component" */).then(c => wrapFunctional(c.default || c))
export const UiInputsSelect = () => import('../../components/ui/inputs/Select.vue' /* webpackChunkName: "components/ui-inputs-select" */).then(c => wrapFunctional(c.default || c))
export const UiInputsVisualSwatch = () => import('../../components/ui/inputs/VisualSwatch.vue' /* webpackChunkName: "components/ui-inputs-visual-swatch" */).then(c => wrapFunctional(c.default || c))
export const UiModalsPopUp = () => import('../../components/ui/modals/PopUp.vue' /* webpackChunkName: "components/ui-modals-pop-up" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/pagination/Pagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiSocialShareModal = () => import('../../components/ui/social/SocialShareModal.vue' /* webpackChunkName: "components/ui-social-share-modal" */).then(c => wrapFunctional(c.default || c))
export const UiSvgHexagonSvg = () => import('../../components/ui/svg/HexagonSvg.vue' /* webpackChunkName: "components/ui-svg-hexagon-svg" */).then(c => wrapFunctional(c.default || c))
export const SectionsAboutSection = () => import('../../components/sections/about-page/AboutSection.vue' /* webpackChunkName: "components/sections-about-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsAnimationTransitionExpand = () => import('../../components/sections/animation/transition-expand.vue' /* webpackChunkName: "components/sections-animation-transition-expand" */).then(c => wrapFunctional(c.default || c))
export const SectionsContactSection = () => import('../../components/sections/contact-page/ContactSection.vue' /* webpackChunkName: "components/sections-contact-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsFaqSection = () => import('../../components/sections/faq-page/FaqSection.vue' /* webpackChunkName: "components/sections-faq-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsDistributersPageCroatianDistributersSection = () => import('../../components/sections/distributers-page/CroatianDistributersSection.vue' /* webpackChunkName: "components/sections-distributers-page-croatian-distributers-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsDistributersPageInternationalDistributersSection = () => import('../../components/sections/distributers-page/InternationalDistributersSection.vue' /* webpackChunkName: "components/sections-distributers-page-international-distributers-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomePageHeroSection = () => import('../../components/sections/home-page/HomePageHeroSection.vue' /* webpackChunkName: "components/sections-home-page-hero-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomePageSmartCardSection = () => import('../../components/sections/home-page/HomePageSmartCardSection.vue' /* webpackChunkName: "components/sections-home-page-smart-card-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomePageSmartNeedleSection = () => import('../../components/sections/home-page/HomePageSmartNeedleSection.vue' /* webpackChunkName: "components/sections-home-page-smart-needle-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHomePageVideoSection = () => import('../../components/sections/home-page/HomePageVideoSection.vue' /* webpackChunkName: "components/sections-home-page-video-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsPrivacyPolicySection = () => import('../../components/sections/privacy-policy-page/PrivacyPolicySection.vue' /* webpackChunkName: "components/sections-privacy-policy-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageBackgroundImageSection = () => import('../../components/sections/product-single-page/BackgroundImageSection.vue' /* webpackChunkName: "components/sections-product-single-page-background-image-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageDeckGallerySection = () => import('../../components/sections/product-single-page/DeckGallerySection.vue' /* webpackChunkName: "components/sections-product-single-page-deck-gallery-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageDescriptionGallerySection = () => import('../../components/sections/product-single-page/DescriptionGallerySection.vue' /* webpackChunkName: "components/sections-product-single-page-description-gallery-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductDescriptionSection = () => import('../../components/sections/product-single-page/ProductDescriptionSection.vue' /* webpackChunkName: "components/sections-product-description-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductImageGridSection = () => import('../../components/sections/product-single-page/ProductImageGridSection.vue' /* webpackChunkName: "components/sections-product-image-grid-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSection = () => import('../../components/sections/product-single-page/ProductSection.vue' /* webpackChunkName: "components/sections-product-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageReviewAddFormSection = () => import('../../components/sections/product-single-page/ReviewAddFormSection.vue' /* webpackChunkName: "components/sections-product-single-page-review-add-form-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSingleImageSection = () => import('../../components/sections/product-single-page/SingleImageSection.vue' /* webpackChunkName: "components/sections-product-single-image-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsTestimonialsSection = () => import('../../components/sections/testimonials-page/TestimonialsSection.vue' /* webpackChunkName: "components/sections-testimonials-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageSmartNeedleDetailsSection = () => import('../../components/sections/product-single-page/smart-needle-single-sections/SmartNeedleDetailsSection.vue' /* webpackChunkName: "components/sections-product-single-page-smart-needle-details-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageSmartNeedleHeroSection = () => import('../../components/sections/product-single-page/smart-needle-single-sections/SmartNeedleHeroSection.vue' /* webpackChunkName: "components/sections-product-single-page-smart-needle-hero-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageSmartCardDetailsSection = () => import('../../components/sections/product-single-page/smart-card-single-sections/SmartCardDetailsSection.vue' /* webpackChunkName: "components/sections-product-single-page-smart-card-details-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsProductSinglePageSmartCardHeroSection = () => import('../../components/sections/product-single-page/smart-card-single-sections/SmartCardHeroSection.vue' /* webpackChunkName: "components/sections-product-single-page-smart-card-hero-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
